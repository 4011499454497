
export default {
  name: "RadioInput",
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    width: {
      type: String,
      default: "33%",
      required: false,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    value: {
      type: Boolean,
      default: "",
      required: false,
    },
  },
  data: () => ({
    selectedValue: "",
  }),
  mounted() {
    this.selectedValue = this.value
  },
  methods: {
    updateValue(value) {
      this.selectedValue = value
      this.$emit("returnValue", value)
    },
  },
}
