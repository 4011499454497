
export default {
  name: "TimePicker",
  props: {
    dynamicId: {
      type: String,
      default: "",
      required: false,
    },
    width: {
      type: String,
      default: "21rem",
      required: false,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    selectAny: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    value: {
      type: [String, Boolean],
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    showTimes: false,
    minutes: [
      { name: "00", value: "00" },
      { name: "15", value: "15" },
      { name: "30", value: "30" },
      { name: "45", value: "45" },
    ],
    hours: [
      { name: "00", value: "00" },
      { name: "01", value: "01" },
      { name: "02", value: "02" },
      { name: "03", value: "03" },
      { name: "04", value: "04" },
      { name: "05", value: "05" },
      { name: "06", value: "06" },
      { name: "07", value: "07" },
      { name: "08", value: "08" },
      { name: "09", value: "09" },
      { name: "10", value: "10" },
      { name: "11", value: "11" },
      { name: "12", value: "12" },
      { name: "13", value: "13" },
      { name: "14", value: "14" },
      { name: "15", value: "15" },
      { name: "16", value: "16" },
      { name: "17", value: "17" },
      { name: "18", value: "18" },
      { name: "19", value: "19" },
      { name: "20", value: "20" },
      { name: "21", value: "21" },
      { name: "22", value: "22" },
      { name: "23", value: "23" },
    ],
    chosenHour: false,
    chosenMinute: false,
    chosenTime: false,
    placeholder: "Select a time",
  }),
  mounted() {
    if (this.value) {
      const [hour, minute] = this.value.split(":")
      this.chosenHour = hour
      this.chosenMinute = minute
      this.chosenTime = this.value
    }
  },
  computed: {
    isTitleVisible() {
      return this.title !== "" && this.showTitle
    },
    showError() {
      return this.isRequired && (!this.value || this.value === "")
    },
    timeSelected() {
      return !!this.chosenHour && !!this.chosenMinute
    },
  },
  methods: {
    updateValue() {
      this.chosenTime = `${this.chosenHour}:${this.chosenMinute}`
      this.showTimes = false
      this.$emit("returnValue", this.chosenTime)
    },
    clearValue() {
      this.chosenTime = false
      this.$emit("returnValue", "")
    },
  },
}
