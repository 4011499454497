
export default {
  name: "CheckboxList",
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    width: {
      type: String,
      default: "33%",
      required: false,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    type: {
      type: String,
      default: "",
      required: false,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    values: [],
    all: false,
  }),
  mounted() {
    this.showAll()
  },
  methods: {
    showAll() {
      this.values === this.options ? (this.all = true) : (this.all = false)
    },
    updateValues() {
      this.$emit("returnValues", this.values.toString())
    },
    updateAll() {
      if (this.values === this.options) {
        this.values = []
        this.all = false
      } else {
        this.values = this.options
        this.all = true
      }
    },
  },
}
