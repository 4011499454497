
export default {
  name: "TextInput",
  props: {
    dynamicId: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      default: "",
      required: false,
    },
    width: {
      type: String,
      default: "20rem",
      required: false,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    maxLength: {
      type: Number,
      default: 2000,
      required: false,
    },
    rows: {
      type: Number,
      default: 3,
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    name: {
      type: String,
      default: "",
      required: false,
    },
    subtitle: {
      type: String,
      default: "",
      required: false,
    },
    placeHolder: {
      type: String,
      default: "Please enter some text",
      required: false,
    },
    isUppercase: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    type: {
      type: String,
      default: "text",
      required: false,
    },
    limit: {
      type: Number,
      default: 1000,
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      localValue: this.isUppercase ? this.value.toUpperCase() : this.value,
      timer: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    }
  },
  computed: {
    isTitleVisible() {
      return this.title !== "" && this.showTitle
    },
    showError() {
      return (
        this.errorMessage.length > 0 ||
        (this.isRequired && this.localValue === "")
      )
    },
  },
  watch: {
    value(to) {
      this.isUppercase
        ? (this.localValue = to.toUpperCase())
        : (this.localValue = to)
    },
    localValue(to) {
      this.handleKeyup()
    },
  },
  methods: {
    handleKeyup() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.returnValue(
          this.type === "email"
            ? this.localValue.toLocaleLowerCase()
            : this.localValue
        )
      }, 700)
    },
    returnValue(field) {
      this.$emit("returnValue", field)
    },
    enterPressed() {
      this.$emit("enterPressed", true)
    },
    handleInput(event) {
      if (this.type === "number") {
        const value = event.target.value
        if (value < 0) {
          this.localValue = 0
        } else {
          this.localValue = value
        }
      } else if (this.type === "currency") {
        const newValue = event.target.value.replace(/,/g, ".");
        this.localValue = newValue
      } else {
        this.localValue = this.isUppercase
          ? event.target.value.toUpperCase()
          : event.target.value
      }
    },
    blockNegativeInput(event) {
      if (this.type === "number" && (event.key === "-" || event.key === "e")) {
        event.preventDefault()
      }
    },
  },
}
