
export default {
  name: "RadioToggle",
  props: {
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    id: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    value: {
      type: [Boolean, Number],
      default: false,
      required: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("returnValue", value)
    },
  },
}
