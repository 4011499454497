
export default {
  name: "MultiSelect",
  props: {
    dynamicId: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      default: "",
      required: false,
    },
    width: {
      type: String,
      default: "100%",
      required: false,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    title: {
      default: false,
      required: false,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Please select an option",
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    },
    fixed: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showOptions: false,
      localValue: this.value,
      unselectedOptions: [],
      optionsToShow: [],
      searchTerm: "",
    }
  },
  computed: {
    showError() {
      return this.errorMessage.length > 0
    },
  },
  watch: {
    value(to) {
      this.localValue = to
      this.$emit("returnValue", to)
    },
    options(to) {
      const selectedValues = this.localValue.map((value) => value.value)
      this.unselectedOptions = to.filter(
        (option) => !selectedValues.includes(option.value)
      )
      this.optionsToShow = this.unselectedOptions
    },
  },
  mounted() {
    this.unselectedOptions = this.options
    this.optionsToShow = this.unselectedOptions
  },
  methods: {
    updateOption(selectedOption) {
      if (this.closeOnSelect) {
        this.showOptions = false
      }
      this.localValue = [...this.localValue, selectedOption]
      this.unselectedOptions = this.options.filter(
        (option) =>
          !this.localValue.map((value) => value.value).includes(option.value)
      )
      if (this.searchTerm)
        this.optionsToShow = this.unselectedOptions.filter((option) =>
          option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      else this.optionsToShow = this.unselectedOptions
      this.$emit("returnValue", this.localValue)
    },
    toggleOptions() {
      this.showOptions = !this.showOptions
    },
    clearValue() {
      this.$emit("returnValue", "")
    },
    closeMultiSelect() {
      this.showOptions = false
    },
    updateSearchTerm(searchTerm) {
      this.searchTerm = searchTerm
      if (searchTerm)
        this.optionsToShow = this.unselectedOptions.filter((option) =>
          option.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      else this.optionsToShow = this.unselectedOptions
    },
    removeSelectedItem(index) {
      this.localValue.splice(index, 1)
      const selectedValues = this.localValue.map((value) => value.value)
      this.unselectedOptions = this.options.filter(
        (option) => !selectedValues.includes(option.value)
      )
      this.optionsToShow = this.unselectedOptions
      this.$emit("returnValue", this.localValue)
    },
  },
}
