
export default {
  name: "DateInput",
  props: {
    dynamicId: {
      type: String,
      default: "",
      required: false,
    },
    width: {
      type: String,
      default: "21rem",
      required: false,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    selectAny: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    value: {
      type: [String, Boolean],
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    placeholder: false,
    showCalendar: false,
    chosenDate: false,
  }),
  computed: {
    isTitleVisible() {
      return this.title !== "" && this.showTitle
    },
    showError() {
      return this.errorMessage.length > 0
    },
  },
  watch: {
    value(to) {
      this.$emit("returnValue", to)
      const dateMoment = this.$moment(this.value).utcOffset(0)
      this.chosenDate = {
        available: true,
        day: dateMoment.format("dddd"),
        full: dateMoment.format("YYYY-MM-DD"),
        month: dateMoment.format("MMM"),
        number: dateMoment.format("DD"),
        string: dateMoment.format("dddd Do MMMM YYYY"),
        year: dateMoment.format("YYYY"),
        iso: dateMoment.toISOString(),
      }
    },
  },
  mounted() {
    this.placeholder = `Please enter a date`
  },
  methods: {
    updateValue(value) {
      this.chosenDate = value
      this.showCalendar = false
      this.$emit("returnValue", value.iso)
    },
    clearValue() {
      this.chosenDate = false
      this.$emit("returnValue", "")
    },
  },
}
