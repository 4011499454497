
export default {
  name: "DynamicForm",
  props: {
    inputs: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      fields: this.inputs,
    }
  },
  mounted() {
    this.fields.forEach((field) => this.updateForm(field))
  },
  computed: {
    indexedFields() {
      return this.fields.map((field, i) => {
        return { ...field, index: i }
      })
    },
  },
  watch: {
    inputs(to) {
      this.fields = to
    },
  },
  methods: {
    updateForm(field) {
      let reg
      let error = ""
      if (field.required && field.value?.length === 0) {
        error = `Please enter ${field.title}`
      }
      if (field.type === "number" && field.value !== "") {
        field.value = Math.max(parseInt(field.value || 0), 0)
      }
      if (field.type === "currency" && field.value !== "") {
        field.value = parseInt(field.value || 0)
      }
      if (field.title === "Street name and number" && field.value.length > 0) {
        reg = /^[^0-9]*$/
        error = reg.test(field.value) ? "Please add your house number" : ""
      }
      if (field.type === "postcode" && field.value.length > 0) {
        field.value = `${field.value
          .toUpperCase()
          .replace(/\s/g, "")
          .slice(
            0,
            field.value.toUpperCase().replace(/\s/g, "").length - 3
          )} ${field.value
          .toUpperCase()
          .replace(/\s/g, "")
          .slice(field.value.toUpperCase().replace(/\s/g, "").length - 3)}`
        reg =
          /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
        error = !reg.test(field.value) ? "Please enter a valid UK postcode" : ""
      }
      if (
        field.type === "password" &&
        field.required &&
        field.value.length > 0
      ) {
        error =
          field.value.length < 4 ? "Password is not the correct length" : ""
      }
      if (field.type === "email" && field.value.length > 0) {
        reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        error = !reg.test(field.value)
          ? "Please enter a valid email address"
          : ""
      }
      this.fields = this.fields.map((input, index) => {
        return {
          ...input,
          value: field.index === index ? field.value : input.value,
          error: field.index === index ? error : input.error,
        }
      })

      this.$emit("returnForm", this.fields)
    },
    enterPressed() {
      this.$emit("enterPressed", true)
    },
  },
}
