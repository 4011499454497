
export default {
  name: "ApiSearchSelect",
  props: {
    dynamicId: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      default: "",
      required: false,
    },
    width: {
      type: String,
      default: "100%",
      required: false,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    title: {
      default: false,
      required: false,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Please select an option",
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    },
    fixed: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    searchCallback: {
      type: Function,
      default: async () => {},
      required: false,
    },
    allowFreeText: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showOptions: false,
      localValue: this.value,
      optionsToShow: [],
      searchTerm: "",
    }
  },
  computed: {
    showError() {
      return this.errorMessage.length > 0
    },
  },
  watch: {
    value(to) {
      this.localValue = to
      this.$emit("returnValue", to)
    },
    options(to) {
      this.optionsToShow = to
    },
  },
  mounted() {
    this.optionsToShow = this.options
  },
  methods: {
    updateOption(option) {
      this.showOptions = false
      this.localValue = option
      this.$emit("returnValue", option)
    },

    toggleOptions() {
      this.showOptions = !this.showOptions
    },
    clearValue() {
      this.$emit("returnValue", "")
    },
    closeApiSearchSelect() {
      this.showOptions = false
    },
    enterFreeText() {
      if (this.allowFreeText) {
        this.updateOption({ name: this.searchTerm, value: this.searchTerm })
      }
    },
    async updateSearchTerm(searchTerm) {
      this.searchTerm = searchTerm
      if (searchTerm) {
        this.optionsToShow = await this.searchCallback(searchTerm)
      } else {
        this.optionsToShow = []
      }
    },
  },
}
