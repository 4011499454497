import { render, staticRenderFns } from "./DynamicForm.vue?vue&type=template&id=54dac379"
import script from "./DynamicForm.vue?vue&type=script&lang=js"
export * from "./DynamicForm.vue?vue&type=script&lang=js"
import style0 from "./DynamicForm.vue?vue&type=style&index=0&id=54dac379&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CheckboxList: require('/codebuild/output/src545922095/src/and-dine-hub/components/CheckboxList.vue').default,RadioInput: require('/codebuild/output/src545922095/src/and-dine-hub/components/RadioInput.vue').default,RadioToggle: require('/codebuild/output/src545922095/src/and-dine-hub/components/RadioToggle.vue').default,DropDown: require('/codebuild/output/src545922095/src/and-dine-hub/components/DropDown.vue').default,ApiSearchSelect: require('/codebuild/output/src545922095/src/and-dine-hub/components/ApiSearchSelect.vue').default,MultiSelect: require('/codebuild/output/src545922095/src/and-dine-hub/components/MultiSelect.vue').default,DateInput: require('/codebuild/output/src545922095/src/and-dine-hub/components/DateInput.vue').default,TimeInput: require('/codebuild/output/src545922095/src/and-dine-hub/components/TimeInput.vue').default,TextInput: require('/codebuild/output/src545922095/src/and-dine-hub/components/TextInput.vue').default})
