
export default {
  name: "DropDown",
  props: {
    dynamicId: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      default: "",
      required: false,
    },
    width: {
      type: String,
      default: "100%",
      required: false,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    title: {
      default: false,
      required: false,
    },
    options: {
      type: [Array, Boolean],
      default: () => [],
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Please select an option",
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    },
    fixed: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showOptions: false,
      localValue: this.value,
      optionsToShow: [],
    }
  },
  computed: {
    showError() {
      return (
        this.isRequired &&
        (!this.localValue ||
          (this.localValue.name === undefined &&
            this.localValue.id === undefined))
      )
    },
  },
  watch: {
    value(to) {
      this.localValue = to
      this.$emit("returnValue", to)
    },
    options(to) {
      this.optionsToShow = to
    },
  },
  mounted() {
    this.optionsToShow = this.options
  },
  methods: {
    updateOption(option) {
      this.showOptions = false
      this.localValue = option
      this.$emit("returnValue", option)
    },
    addNew(option) {
      this.showOptions = false
      this.localValue = option
      this.$emit("addNew", true)
    },
    toggleOptions() {
      this.showOptions = !this.showOptions
    },
    clearValue() {
      this.localValue = ""
      this.$emit("returnValue", "")
    },
    closeDropdown() {
      this.showOptions = false
    },
    updateSearchTerm(searchTerm) {
      if (searchTerm)
        this.optionsToShow =
          this.options &&
          this.options.filter((option) =>
            option.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
      else this.optionsToShow = this.options
    },
  },
}
