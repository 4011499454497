
export default {
  name: "Calendar",
  props: {
    chosenDate: {
      required: false,
      default: false,
    },
    dateList: {
      type: [Array, Boolean],
      required: false,
      default: false,
    },
    hoveredDate: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    deliveryDays: {
      required: false,
      default: () => [],
      type: Array,
    },
    width: {
      required: false,
      default: "100%",
      type: String,
    },
    yearly: {
      required: false,
      default: false,
      type: Boolean,
    },
    fullScreen: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      blanks: 0,
      calendarDate: this.$moment().utcOffset(0, true).startOf("day"),
      days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      dates: [],
      today: {
        moment: this.$moment().utcOffset(0, true).startOf("day"),
        number: this.$moment().utcOffset(0, true).format("DD"),
        month: this.$moment().utcOffset(0, true).format("MMMM"),
        year: this.$moment().utcOffset(0, true).format("YYYY"),
      },
      monthDiff: 0,
    }
  },
  mounted() {
    this.getDates()
  },
  methods: {
    getDates() {
      this.calendarDate.startOf("month")
      const startWeekday = this.calendarDate.day()
      const startDay = startWeekday === 0 ? 6 : startWeekday - 1
      this.blanks = startDay

      this.dates = Array.from(
        { length: this.calendarDate.daysInMonth() },
        (_, i) => {
          const date = this.calendarDate.clone().add(i, "days")
          return {
            number: date.format("DD"),
            day: date.format("dddd"),
            month: date.format("MMMM"),
            year: date.format("YYYY"),
            full: date.format("YYYY-MM-DD"),
            string: `${date.format("dddd")} ${date.format("Do")} ${date.format(
              "MMMM"
            )} ${date.format("YYYY")}`,
            iso: date.toISOString(),
            inPast: date.isBefore(this.today.moment, "day"),
            isToday: date.isSame(this.today.moment, "day"),
            available: true,
          }
        }
      )
      if (this.deliveryDays.length > 0) {
        this.dates = this.dates.map((date) => ({
          ...date,
          available: this.deliveryDays.some((d) => d.full === date.full),
        }))
      }
    },
    prevYear() {
      this.calendarDate.subtract(1, "year")
      this.getDates()
    },
    nextYear() {
      this.calendarDate.add(1, "year")
      this.getDates()
    },
    prevMonth() {
      this.calendarDate.subtract(1, "month")
      this.getDates()
    },
    nextMonth() {
      this.calendarDate.add(1, "month")
      this.getDates()
    },
    chooseDate(date) {
      if (this.dateList) {
        this.dateList.includes(date.full) && this.$emit("returnDate", date.full)
      } else {
        this.$emit("returnDate", date)
        this.$emit("closeCalendar")
      }
    },
    updateHoveredDate(date) {
      this.$emit("returnHovered", date.full)
    },
  },
}
